import React from "react";
import "./resources.css";

const Resources = () => {
  return (
    <div className="mainContainer">
      <div className="resourcesContainer w-100 ">
        <h2 className="faqTitle ">Resources</h2>
      </div>
      <div className="mainText mt-3 row p-5 font-weight-bold text-left mb-2">
        <div className="mb-2">
          <a
            href="https://www.signupgenius.com/go/9040A4FABA82EAAFB6-50162995-scusd#/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://thum.io/get/auth/2171-0340629718298985/width/200/crop/900/http://www.signupgenius.com/go/9040A4FABA82EAAFB6-scusd17"
              alt="http://www.signupgenius.com/go/9040A4FABA82EAAFB6-scusd17"
            />
          </a>
        </div>
        <div className="">
          {" "}
          <a
            href="https://www.signupgenius.com/go/9040A4FABA82EAAFB6-50162995-scusd#/"
            target="_blank"
            rel="noopener noreferrer"ß
          >
            https://www.signupgenius.com/go/9040A4FABA82EAAFB6-scusd18
          </a>
        </div>
      </div>
    </div>
  );
};

export default Resources;